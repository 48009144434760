<template>
  <!-- <transition name="el-fade-in-linear" mode="out-in"> -->
    <div class="page">
      <div v-if="course.id">
        <div class="zhanwei">
          <!-- 占位 -->
        </div>
        <div class="connent_top">
          <div class="title">
            {{ course.course_name
            }}{{ course.course_tag ? "（" + course.course_tag + "）" : "" }}
          </div>
          <!-- <div class="time" v-if="course.course_expire_time == '永久有效'">
            {{ course.course_class_hour }} 课时 | 过期时间：{{
              course.course_expire_time
            }}
          </div> -->
          <div class="time">
            {{ course.course_class_hour }} 课时 | 过期时间：{{
              course.course_expire_time
            }}
          </div>
          <div class="num_">{{ course.course_people }} 人正在学</div>
          <div
            class="tab_wrap"
            v-if="course_relation && course_relation.length"
          >
            <div
              :class="
                item.course_id == item.cover_course_id
                  ? 'active item_'
                  : 'item_'
              "
              v-for="(item, index) in course_relation"
              :key="index"
              @click="change_subject(item.cover_course_id)"
            >
              {{ item.tag_name }}
            </div>
          </div>
          <div class="dec">
            <template v-if="a == 1">
              <div class="pt_price">
                拼团
                <b>￥{{ course.course_present_price }}</b>
              </div>
              <div class="old_price">￥{{ course.course_original_price }}</div>
            </template>
            <template v-else>
              <!-- course_price_type 1正常价格 2咨询 3隐藏 -->
              <div class="pt_price" v-if="course.course_price_type==1">
                <b>{{
                  course.course_present_price == 0
                    ? "免费"
                    : "¥" + course.course_present_price
                }}</b>
              </div>
            </template>
            <!-- <template v-if="a == 1">
              <div class="buy_btn">
                <el-button type="warning" round @click="go_orderinfo"
                  >原价购买￥99</el-button
                >
                <el-button type="danger" round @click="go_orderinfo"
                  >两人拼团￥19.90</el-button
                >
                <div class="icon_img">
                  <img src="@/assets/login/kf.png" alt="" />
                  客服
                </div>
              </div>
            </template>
            <template v-else> -->
            <div class="buy_btn">
               <!-- course_price_type 1正常价格 2咨询 3隐藏  -->
              <template v-if="course.course_price_type==1">
                <div v-if="course.is_join == 1">
                  <template v-if="course.course_type==2">
                    <el-button v-if="course.course_is_free == 1"
                      type="info"
                      plain
                      class="payed_css"
                      @click="dialogVisible = true"
                      >已购买</el-button
                    >
                    <el-button v-else
                      type="info"
                      plain
                      class="payed_css"
                      @click="dialogVisible = true"
                      >已报名</el-button
                    >
                  </template>
                  <template v-else>
                    <el-button
                      type="danger"
                      round
                      @click="handelToWatch"
                      >立即学习</el-button
                    >
                  </template>
                </div>
                <div v-else>
                  <el-button
                    v-if="course.course_is_free == 1"
                    type="danger"
                    round
                    @click="go_orderinfo"
                    >立即购买</el-button
                  >
                  <el-button v-else @click="go_orderinfo" type="danger" round
                    >立即报名</el-button
                  >
                </div>
              </template>
              <template v-else-if="course.course_price_type==2">
                <el-button
                  type="danger"
                  round
                  @click="handleKefu"
                  >立即咨询</el-button
                >
              </template>
              <template v-else-if="course.course_price_type==3"></template>

              <!-- <div class="icon_img" @click="handleKefu">
                <img src="@/assets/login/kf.png" alt="" />
                客服
              </div> -->
            </div>
            <!-- </template> -->
          </div>
        </div>
        <!-- 连报 -->
        <div class="lianbao container" style="display: none">
          <div class="title">
            <div class="left">
              联报
              <p>两科联报减 399 元，三科联报减 700 元</p>
            </div>
            <div class="right">购买须知</div>
          </div>
          <div class="connent">
            <div class="center">
              <div class="item">
                <div class="name">2022MBA/EMBA/MPA/MEM畅学备考班（数学一）</div>
                <div class="prcie"><i></i> ￥<span>19.09</span></div>
              </div>
              <div class="item">
                <div class="name">2022MBA/EMBA/MPA/MEM畅学备考班（数学一）</div>
                <div class="prcie"><i></i> ￥<span>19.09</span></div>
              </div>
              <div class="item">
                <div class="name">2022MBA/EMBA/MPA/MEM畅学备考班（数学一）</div>
                <div class="prcie"><i></i> ￥<span>19.09</span></div>
              </div>
              <div class="item">
                <div class="name">2022MBA/EMBA/MPA/MEM畅学备考班（数学一）</div>
                <div class="prcie"><i></i> ￥<span>19.09</span></div>
              </div>
            </div>
            <div class="bottom">
              <div class="num">已选 (1)</div>
              <div class="item_wrap">
                <div class="item2">
                  <div class="name">
                    2022MBA/EMBA/MPA/MEM畅学备考班（数学一）
                  </div>
                  <div class="prcie">￥<span>19.09</span></div>
                </div>
                <div class="item2">
                  <div class="name">
                    2022MBA/EMBA/MPA/MEM畅学备考班（数学一）
                  </div>
                  <div class="prcie">￥<span>19.09</span></div>
                </div>
                <div class="span">=</div>
                <div class="price_btn">
                  <div class="top">
                    <div class="prcie_new">￥<span>19.09</span></div>
                    <div class="prcie_old">￥19.09</div>
                  </div>
                  <div class="btn_wrap">立即购买</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 详情 -->
        <div class="connent_warp">
          <div class="left">
            <ul class="tab_wrap">
              <li
                :class="courrent_index == item.tabid ? 'on' : ''"
                v-for="(item, index) in tab_list"
                :key="index"
                @click="change_tab(item.tabid)"
                v-if="item.show"
              >
                <i></i> {{ item.name }}
              </li>
            </ul>
            <div class="connent_tab">
              <!-- 详情 -->
              <div class="detail" v-if="courrent_index == 1">
                <img
                  v-for="(item, index) in course.course_info_src"
                  :key="index"
                  :src="item.src"
                  alt=""
                />
                <img
                  v-for="(item, index) in course.teacher_top_img"
                  :key="'teacher_top' + index"
                  :src="item.src"
                  alt=""
                />
                <template v-for="(item, index) in course.teacher_detail">
                  <img
                    v-if="item"
                    :key="'teacher' + index"
                    :src="item.src"
                    alt=""
                  />
                </template>

                <img
                  v-for="(item, index) in course.course_common_src"
                  :key="'common' + index"
                  :src="item.src"
                  alt=""
                />
              </div>
              <div class="course_timetable" v-if="courrent_index == 2">
                <course_timetable :course="course" :course_is_free="course.course_is_free"/>
              </div>
              <div class="teacher" v-if="courrent_index == 3">
                <div
                  class="item_teacher"
                  v-for="(item, index2) in course.teacher"
                  :key="index2"
                >
                  <img
                    :src="item.teacher_avatar"
                    alt=""
                    onerror="this.src='https://success-image.successkaoyan.com/userlogo/default/default01.png'"
                  />
                  <div class="r">
                    <div class="name">{{ item.teacher_name }}</div>
                    <div class="type">{{ item.teacher_title }}</div>
                    <div class="text">
                      {{ item.teacher_desc }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="rule" v-if="courrent_index == 4">
                1. 点击下方拼团购买按钮，付款成功即开团；<br />
                2.将拼团活动通过微信/QQ等分享给好友，在有效期内满足成团人数即拼团成功；<br />
                3.超过有效期未满足成团人数即拼团失败，系统会在2个工作日内自动退款；<br />
                4. 您可以在“我的”，实时查看拼团进度。
              </div>
            </div>
          </div>
          <div class="right" v-if="rem_list.length">
            <div class="title">课程推荐</div>
            <ul>
              <li
                v-for="(item, index) in rem_list"
                :key="index"
                @click="go_rem_detail(item)"
              >
                <div class="name">{{ item.course.course_name }}</div>
                <div class="dec">
                  <div class="label">{{ item.course.course_tag }}</div>
                  <div class="text">
                    <!-- 直播<i></i> -->
                    {{ item.course.course_class_hour }}课时
                    {{ item.course.course_people }}人在学
                  </div>
                </div>
                <div class="bottom">
                  <div class="left_teac">
                    <template v-for="(t_item, index1) in item.course.teacher">
                      <div class="l" :key="index1" v-if="index1 < 5">
                        <img :src="t_item.teacher_avatar" alt="" />
                        <p>{{ t_item.teacher_name }}</p>
                      </div>
                    </template>
                  </div>

                  <div class="price">
                    <!-- <p class="old">¥ 1000</p> -->
                    <p class="now">
                      {{
                        item.course.course_present_price == 0
                          ? "免费"
                          : "¥" + item.course.course_present_price
                      }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <el-dialog
        :title="market.button_desc"
        :visible.sync="dialogVisible"
        width="300px">
        <div class="tips_market">
          <img :src="market.market_url" alt="" style="width:100%">
          <span v-html="market.market_desc" style="text-align: left;display: inline-block;"></span>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { compare_down } from "@/config/cookie.js";
import course_timetable from "@/components/course_info/course_timetable.vue";
import api from "@/config/api.js";

import { mapActions } from "vuex";
let Base64 = require("js-base64").Base64;
export default {
  name: "course_info",
  data() {
    return {
      a: 0,
      subject: [
        {
          tabid: 1,
          name: "英语一",
        },
        {
          tabid: 2,
          name: "英语二",
        },
        {
          tabid: 3,
          name: "政治",
        },
        {
          tabid: 4,
          name: "择校择专",
        },
      ],
      // 下方tab
      tab_list: [
        {
          tabid: 1,
          name: "详情",
          show:false
        },
        {
          tabid: 2,
          name: "课表",
          show:false
        },
        {
          tabid: 3,
          name: "老师",
          show:false
        },
        // {
        //   tabid: 4,
        //   name: "规则",
        // },
      ],
      courrent_index: 1,
      course: {},
      course_id: NaN,
      re_url: "",
      course_relation: [],
      rem_list: [], //推荐课程

      dialogVisible:false,
      market:{}
    };
  },
  mounted() {
    this.course_id = this.$route.params.course_id;
    // 获取当前的路径
    this.re_url = this.$route.path;
    // console.log(this.re_url);
    this.getData(this.$route.params.course_id);
    this.getData_rem();
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      //注意对方给你的是毫秒还是秒，如果是毫秒需要转秒（*1000）
      let y = date.getFullYear();
      // console.log(y);
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return y + "." + MM + "." + d + " ";
    },
  },

  created() {},
  methods: {
    ...mapActions(["setUserAndState"]),
    async getData(id) {
      try {
        const res = await api.detail({
          id: id,
        });
        if (res.code == 0) {
          this.course = res.result;
          // this.course.customer_service_type = 1;
          // this.course.customer_service_content = '400-8030-969';
          // this.course.is_join = 1
          if(this.course.is_join == 1&&this.course.course_type==2){
            this.getMarket()
          }
          this.chapter_arr = res.result.chapter; //课表数据
          this.course_relation = res.result.course_relation; // 课套信息
          if(res.result.course_info_src.length||res.result.teacher_detail.length||res.result.course_common_src.length){
            this.tab_list[0].show = true
          }
          if(res.result.chapter.length){
            this.tab_list[1].show = true
          }
          if(res.result.teacher.length){
            this.tab_list[2].show = true
          }
          // course_type 1线上课程 2线下课程
        } else if (res.code == 20001 || res.code == 20003) {
          // 课程不存在
          let islogin = await this.islogin();
          if (islogin) {
            this.$router.push(`/mine/my_course?item=1`);
          } else {
            this.$router.push(`/login?re_url=${this.re_url}`);
          }
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 推荐课程
    async getData_rem() {
      try {
        const res = await api.recom();
        if (res.code == 0) {
          if (res.result && res.result.info) {
            this.rem_list = res.result.info;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    change_tab(e) {
      this.courrent_index = e;
    },
    change_subject(e) {
      let { href } = this.$router.resolve(`/course_info/${e}`);
      window.open(href, "_blank");
    },

    async go_orderinfo() {
      let islogin = await this.islogin();
      if (islogin) {
        if (this.course.course_is_shelf == 1) {
          this.$message({
            showClose: true,
            message: "该课程已下架",
            type: "warning",
          });
          return;
        }
        if (this.course.course_is_expire == 1) {
          this.$message({
            showClose: true,
            message: "该课程已过期",
            type: "warning",
          });
          return;
        }
        var base64_checked_str = Base64.encode(
          JSON.stringify([
            {
              good_id: this.course.good_id,
              num: 1,
            },
          ])
        );
        let { href } = this.$router.resolve(
          `/order_info/${base64_checked_str}`
        );
        window.open(href, "_blank");
      } else {
        this.$router.push(`/login?re_url=${this.re_url}`);
      }
    },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },

    handelToWatch() {
      // /course_watch/${course_id}/${chapter_id}/${section_id}
      if (this.course.course_is_shelf == 1) {
        this.$message({
          showClose: true,
          message: "该课程已下架",
          type: "warning",
        });
        return;
      }
      if (this.course.course_is_expire == 1) {
        this.$message({
          showClose: true,
          message: "该课程已过期",
          type: "warning",
        });
        return;
      }
      let { href } = this.$router.resolve(`/course_watch/${this.course_id}`);
      window.open(href, "_blank");
    },
    handleKefu() {
      // customer_service_type 0没有 1电话 2乐语 3其它
      let customer_service_content_ = this.course.customer_service_content;
      if(this.course.customer_service_type==1){
        if(customer_service_content_){
          this.$alert(customer_service_content_, '咨询热线', {
            confirmButtonText: '确定',
          });
        }
        
      }else if(this.course.customer_service_type==2){
        if(customer_service_content_){
          // doyoo.util.openChat("g=10085061");
          // return false;
          var aLink = document.createElement("a");
          aLink.target = "blank";
          aLink.href = customer_service_content_;
          aLink.click();
        }
      }
    },
    go_rem_detail(course_item) {
      let { href } = this.$router.resolve(
        `/course_info/${course_item.course.id}`
      );
      window.open(href, "_blank");
      // this.$router.push( `/course_info/${course_item.course.id}`)
    },
    async getMarket(){
      const res = await api.course_market({
        course_id: this.course_id,
      });
      if(res.code==0){
        this.market = res.result;
      }
    }
  },
  components: {
    // nav_top,
    course_timetable,
    // footer_,
  },
};
</script>
<style scoped>
.buy_btn .el-button--warning {
  width: 280px;
  height: 56px;
  background: #fec435;
  border-radius: 8px;
  border: none;
  font-size: 20px;
  
  font-weight: 400;
  color: #ffffff;
  margin-right: 20px;
}
.buy_btn .el-button--danger {
  width: 280px;
  height: 56px;
  background: #f96158;
  border-radius: 8px;

  font-size: 20px;
  
  font-weight: 400;
  color: #ffffff;
}
.payed_css{
  width: 280px;
  height: 56px;
  font-size: 20px;
  font-weight: 400;
}
</style>

<style lang="less" scoped>
.page {
  background: #f5f6f8;
  min-height: 100vh;
}
.zhanwei {
  height: 22px;
}
.connent_top {
  padding: 40px 65px;
  background: #ffffff;
  border-radius: 16px;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  width: 1200px;
  margin-bottom: 22px;
  .title {
    font-size: 32px;
    
    font-weight: 600;
    color: #333333;
    line-height: 38px;
  }
  .time {
    font-size: 16px;
    
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    margin-top: 10px;
  }
  .num_ {
    font-size: 14px;
    
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    margin-top: 10px;
  }
  .tab_wrap {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .item_ {
      width: 76px;
      height: 35px;
      line-height: 35px;
      background: #f6f6fb;
      border-radius: 8px;
      margin-right: 12px;
      font-size: 16px;
      
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      text-align: center;
    }
    .item_:hover {
      background-color: #479dff;
      color: #ffffff;
    }
    .active {
      background-color: #479dff;
      color: #ffffff;
    }
  }
  .dec {
    margin-top: 40px;
    .pt_price {
      font-size: 36px;
      
      font-weight: 500;
      color: #f96158;
      line-height: 33px;
      display: inline-block;
      margin-right: 24px;
    }
    .old_price {
      font-size: 20px;
      
      font-weight: 500;
      color: #999999;
      line-height: 28px;
      text-decoration: line-through;
      display: inline-block;
    }
  }
  .buy_btn {
    display: flex;
    align-items: center;
    margin-top: 26px;
    .icon_img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      margin-left: 23px;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
        margin-top: 4px;
      }
    }
  }
}
.connent_warp {
  padding-bottom: 50px;
  margin: 0 auto;
  width: 1200px;
  display: inline-flex;
  justify-content: space-between;

  .left {
    width: 780px;
    padding-bottom: 10px;
    // height: 467px;
    background: #ffffff;
    border-radius: 16px;
    .tab_wrap {
      padding: 24px 36px;
      display: flex;
      align-items: center;
      font-size: 18px;
      
      font-weight: 400;
      color: #333333;
      line-height: 28px;

      li {
        font-size: 18px;
        
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        position: relative;
        margin-right: 90px;
        cursor: pointer;
      }
      li:hover {
        font-weight: 600;
      }
      li i {
        display: none;
        width: 22px;
        height: 4px;
        background: #479dff;
        border-radius: 2px;
        position: absolute;
        left: 7px;
        bottom: -8px;
      }
      li:hover i {
        display: block;
      }

      li.on {
        font-weight: 600;
      }
      li.on i {
        display: block;
        width: 22px;
        height: 4px;
        background: #479dff;
        border-radius: 2px;
        position: absolute;
        left: 7px;
        bottom: -8px;
      }
    }
    .connent_tab {
      .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .teacher {
        border-top: 1px solid #e9e9e9;
        padding: 14px 35px;
        .item_teacher {
          padding-top: 23px;
          display: flex;
          align-items: flex-start;
          img {
            width: 50px;
            height: 50px;
            // background: #479dff;
            border-radius: 50%;
          }
          .r {
            text-align: left;
            margin-left: 13px;
            border-bottom: 1px solid #e9e0e9;
            padding-bottom: 23px;
            width: 100%;
            .name {
              font-size: 20px;
              
              font-weight: 600;
              color: #333333;
              line-height: 28px;
            }
            .type {
              font-size: 14px;
              
              font-weight: 400;
              color: #666666;
              line-height: 28px;
            }
            .text {
              font-size: 14px;
              
              font-weight: 500;
              color: #333333;
              line-height: 19px;
              text-align: justify;
            }
          }
        }
      }
      .rule {
        border-top: 1px solid #e9e9e9;
        padding: 14px 35px;
        font-size: 14px;
        
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        text-align: left;
      }
    }
  }
  .right {
    text-align: left;
    width: 398px;
    background: #ffffff;
    border-radius: 16px;
    padding: 27px 0 17px;
    height: fit-content;
    .title {
      font-size: 22px;
      
      font-weight: bold;
      color: #333333;
      line-height: 28px;
      padding: 0 33px;
      // margin-bottom: 19px;
    }
    ul {
      li:last-of-type {
        border: none;
      }
      li {
        cursor: pointer;
        padding: 17px 0;
        border-bottom: 1px solid #e9e9e9;
        .name {
          padding: 0 33px;
          font-size: 18px;
          
          font-weight: 600;
          color: #151515;
        }
        .dec {
          padding: 0 33px;

          display: flex;
          align-items: center;
          margin-top: 10px;
          .label {
            text-align: center;
            width: 66px;
            height: 21px;
            border: 1px solid #00c87f;
            border-radius: 4px;
            font-size: 14px;
            
            font-weight: 400;
            // line-height: 21px;
            color: #00c87f;
          }
          .text {
            font-size: 14px;
            
            font-weight: 400;
            color: #666666;
            // margin-bottom: 6px;
            margin-left: 10px;
          }
          i {
            width: 2px;
            height: 2px;
            background: #666666;
            display: inline-block;
            border-radius: 50%;
            margin: 0 5px;
            margin-bottom: 4px;
          }
        }
        .bottom {
          margin-top: 22px;
          padding: 0 33px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .left_teac {
            width: 80%;
            overflow: hidden;
            display: flex;
            align-items: center;
          }
          .l {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 5px;
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
            p {
              font-size: 14px;
              
              font-weight: 400;
              color: #999999;
              margin-top: 5px;
            }
          }
          .price {
            display: flex;
            align-items: center;

            .old {
              margin-right: 19px;
              font-size: 18px;
              
              font-weight: 500;
              color: #d0d0d0;
            }
            .now {
              font-size: 18px;
              
              font-weight: 600;
              color: #f96158;
            }
          }
        }
      }
    }
  }
}

.lianbao {
  // height: 400px;
  background: #ffffff;
  border-radius: 16px;
  margin: 0 auto;
  margin-bottom: 22px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    height: 56px;
    background: #fdf6e0;
    border-radius: 16px 16px 0px 0px;
    font-weight: 600;
    box-sizing: border-box;
    .left {
      font-size: 20px;
      
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
      p {
        margin-left: 14px;
        font-size: 16px;
        
        font-weight: 400;
        color: #f96158;
      }
    }
    .right {
      font-size: 18px;
      
      font-weight: 400;
      color: #999999;
    }
  }
  .connent {
    padding: 23px 30px 40px;
    // box-sizing: border-box;
    .center {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 20px;
      .item:nth-of-type(4n) {
        margin-right: 0;
      }
      .item {
        margin-right: 20px;
        text-align: left;
        padding: 14px 22px 22px;
        width: 270px;
        height: 120px;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        .name {
          font-size: 16px;
          
          font-weight: 400;
          color: #333333;
        }
        .prcie {
          display: flex;
          align-items: center;
          font-size: 14px;
          
          font-weight: 500;
          color: #f96158;
          line-height: 15px;
          span {
            font-size: 22px;
            
            margin-top: -3px;
          }
          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 1px solid #e9e9e9;
            background-color: #fff;
            margin-right: 10px;
          }
        }
      }
    }

    .bottom {
      text-align: left;
      padding-top: 20px;
      .num {
        // width: 100px;
        // height: 20px;
        font-size: 20px;
        
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .item_wrap {
        display: flex;
        align-items: center;
      }
      .item:nth-of-type(4n) {
        margin-right: 0;
      }
      .item2 {
        margin-right: 20px;
        text-align: left;
        padding: 14px 22px 22px;
        width: 270px;
        height: 120px;
        border-radius: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #f6f6fb;
        border-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;
        .name {
          font-size: 16px;
          
          font-weight: 400;
          color: #333333;
        }
        .prcie {
          display: flex;
          align-items: center;
          font-size: 14px;
          
          font-weight: 500;
          color: #333333;
          line-height: 15px;
          span {
            font-size: 22px;
            
            margin-top: -3px;
          }
          i {
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 1px solid #e9e9e9;
            background-color: #fff;
            margin-right: 10px;
          }
        }
      }
      .span {
        font-size: 20px;
        
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-right: 20px;
      }
      .price_btn {
        .top {
          display: flex;
          align-items: center;
          .prcie_new {
            display: flex;
            align-items: center;
            font-size: 14px;
            
            font-weight: 500;
            color: #f96158;
            line-height: 15px;
            span {
              font-size: 22px;
              
              margin-top: -3px;
            }
          }
          .prcie_old {
            display: flex;
            align-items: center;
            font-size: 14px;
            
            font-weight: 400;
            color: #999999;
            text-decoration: line-through;
            margin-left: 8px;
          }
        }

        .btn_wrap {
          width: 136px;
          height: 44px;
          background: #f96158;
          border-radius: 8px;
          text-align: center;
          line-height: 44px;
          color: #fff;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
