<template>
  <div class="course_timetable">
    <template v-for="(item, index) in course.chapter">
      <div
        class="connent_item"
        :key="index"
        v-if="course.chapter[index].section.length > 0"
      >
        <div class="title_">
          <img src="@/assets/login/kebiao_icon.png" alt="" />
          {{ item.chapter_name }}
        </div>
        <ul class="item_connent">
          <li
            v-for="(item_section, index1) in item.section"
            :key="index1"
            @click="handleClickSection(item_section)"
          >
            <template v-if="item_section.section_type == 1">
              <!--  [1点播]  2直播 3讲义  4QQ群 5微信  6练习 7作业 -->
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="title">{{ item_section.section_name }}</div>
                  <div class="time">
                    <img
                      v-if="item_section.section_audition&&!course.is_join&&course.course_is_free"
                      src="@/assets/login/play.png"
                      alt=""
                    />
                    <img v-else src="@/assets/login/type_play.png" alt="" />
                    <p :class="item_section.section_audition&&!course.is_join&&course.course_is_free ? 'red' : ''">
                      {{ item_section.section_audition&&!course.is_join&&course.course_is_free? "试听" : "视频" }}
                      <template v-if="item_section.section_duration">
                        <span>|</span>{{ item_section.section_duration }}
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <div v-if="item_section.section_is_online">
                    <!-- <div class="btn" v-if="item_section.section_audition">
                      试听
                    </div> -->
                    <div class="video_play btn">播放</div>
                  </div>
                  <div v-else class="video_noline btn">未上线</div>
                </div>
                <div v-else class="right">
                  <template v-if="course.course_is_free">
                    <div v-if="item_section.section_audition" class="btn">
                      试听
                    </div>
                    <div v-else class="">
                      <img src="@/assets/course/lock.png" alt="" />
                    </div>
                  </template>
                  <div v-else class="btn">
                    播放
                  </div>
                </div>
              </template>
            </template>
            <!--  1点播  [2直播] 3讲义  4QQ群 5微信  6练习 7作业 -->
            <template v-if="item_section.section_type == 2">
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="title">{{ item_section.section_name }}</div>
                  <div class="time">
                    <img src="@/assets/login/zhibo.png" alt="" />
                    <p>直播<span>|</span>{{ item_section.section_time }}</p>
                  </div>
                </div>
              </div>
              <!-- 直播状态 0点播使用    1 预约 2直播中  3直播结束  4回放  5无回放） -->
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <div
                    v-if="item_section.section_status == 1"
                    class="subscribe btn"
                  >
                    未开始
                  </div>
                  <div
                    v-if="item_section.section_status == 2"
                    class="living btn"
                  >
                    <img src="@/assets/course/living.gif" alt="" />
                    直播中
                  </div>
                  <div
                    v-if="item_section.section_status == 3"
                    class="live_end btn"
                  >
                    已结束
                  </div>
                  <div
                    v-if="item_section.section_status == 4"
                    class="playback btn"
                  >
                    回放
                  </div>
                  <div
                    v-if="item_section.section_status == 5"
                    class="noplayback btn"
                  >
                    无回放
                  </div>
                </div>
                <div v-else class="right">
                  <template v-if="course.course_is_free">
                    <template v-if="item_section.section_audition">
                      <div
                        v-if="item_section.section_status == 1"
                        class="subscribe btn"
                      >
                        未开始
                      </div>
                      <div
                        v-if="item_section.section_status == 2"
                        class="living btn"
                      >
                        <img src="@/assets/course/living.gif" alt="" />
                        直播中
                      </div>
                      <div
                        v-if="item_section.section_status == 3"
                        class="live_end btn"
                      >
                        已结束
                      </div>
                      <div
                        v-if="item_section.section_status == 4"
                        class="playback btn"
                      >
                        回放
                      </div>
                      <div
                        v-if="item_section.section_status == 5"
                        class="noplayback btn"
                      >
                        无回放
                      </div>
                    </template>
                    <div v-else class="">
                      <img src="@/assets/course/lock.png" alt="" />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="item_section.section_status == 1"
                      class="subscribe btn"
                    >
                      未开始
                    </div>
                    <div
                      v-if="item_section.section_status == 2"
                      class="living btn"
                    >
                      <img src="@/assets/course/living.gif" alt="" />
                      直播中
                    </div>
                    <div
                      v-if="item_section.section_status == 3"
                      class="live_end btn"
                    >
                      已结束
                    </div>
                    <div
                      v-if="item_section.section_status == 4"
                      class="playback btn"
                    >
                      回放
                    </div>
                    <div
                      v-if="item_section.section_status == 5"
                      class="noplayback btn"
                    >
                      无回放
                    </div>
                  </template>
                  <!-- <div v-if="item_section.section_audition" class="btn red">
                    试听
                  </div>
                  <div v-else class="">
                    <img src="@/assets/course/lock.png" alt="" />
                  </div> -->
                </div>
              </template>
            </template>
            <!--  1点播  2直播 [3讲义]  4QQ群 5微信  6练习 7作业 -->
            <template v-if="item_section.section_type == 3">
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="title">{{ item_section.section_name }}</div>
                  <div class="time">
                    <img src="@/assets/login/jiangyi.png" alt="" />
                    <p>讲义</p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <template v-if="item_section.section_is_online">
                    <div class="btn">查看</div>
                  </template>
                  <div v-else class="video_noline btn">未上线</div>
                </div>
                <div v-else class="right">
                  <template v-if="course.course_is_free">
                    <div v-if="item_section.section_audition" class="btn">
                      试听
                    </div>
                    <div v-else class="">
                      <img src="@/assets/course/lock.png" alt="" />
                    </div>
                  </template>
                  <div v-else class="btn">
                    查看
                  </div>
                </div>
              </template>
            </template>
            <!--  1点播  2直播 3讲义  [4QQ群] 5微信  6练习 7作业 -->
            <template v-if="item_section.section_type == 4">
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="time">
                    <img src="@/assets/login/erweimapng.png" alt="" />
                    <p>qq</p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <div class="btn">查看</div>
                </div>
                <div v-else class="right">
                  <div class="">
                    <img src="@/assets/course/lock.png" alt="" />
                  </div>
                </div>
              </template>
            </template>
            <!--  1点播  2直播 3讲义  4QQ群 [5微信]  6练习 7作业 -->
            <template v-if="item_section.section_type == 5">
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="time">
                    <img src="@/assets/login/erweimapng.png" alt="" />
                    <p>微信</p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <div class="btn">查看</div>
                </div>
                <div v-else class="right">
                  <div class="">
                    <img src="@/assets/course/lock.png" alt="" />
                  </div>
                </div>
              </template>
            </template>
            <!--  1点播  2直播 3讲义  4QQ群 5微信  [6练习] 7作业 -->
            <template v-if="item_section.section_type == 6">
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="time">
                    <img src="@/assets/login/lianxi.png" alt="" />
                    <p>练习</p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <div class="btn">查看</div>
                </div>
                <div v-else class="right">
                  <div class="">
                    <img src="@/assets/course/lock.png" alt="" />
                  </div>
                </div>
              </template>
            </template>
            <!--  1点播  2直播 3讲义  4QQ群 5微信  6练习 [7作业] -->
            <template v-if="item_section.section_type == 7">
              <div class="left">
                <div class="num">{{ fix(index1 + 1, 2) }}</div>
                <div class="dec_wrap">
                  <div class="title">{{ item_section.section_name }}</div>
                  <div class="time">
                    <img src="@/assets/login/home_work.png" alt="" />
                    <p>
                      作业<span>|</span
                      >{{
                        item_section.sectionTask.is_task ? "已完成" : "未完成"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  course.course_is_shelf == 1 || course.course_is_expire == 1
                "
              >
                <div class="right">
                  <div class="btn">
                    {{ course.course_is_shelf == 1 ? "已下架" : "已过期" }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="course.is_join" class="right">
                  <div class="btn">查看</div>
                </div>
                <div v-else class="right">
                  <div class="">
                    <img src="@/assets/course/lock.png" alt="" />
                  </div>
                </div>
              </template>
            </template>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/config/api.js";
let Base64 = require("js-base64").Base64;
export default {
  props: {
    course: Object,
  },
  data() {
    return {};
  },
  mounted() {
    // 获取当前的路径
    this.re_url = this.$route.path;
  },
  methods: {
    // 个位数转化
    fix(num, length) {
      return ("" + num).length < length
        ? (new Array(length + 1).join("0") + num).slice(-length)
        : "" + num;
    },
    async handleClickSection(item_section) {
      if (this.course.course_is_shelf == 1) {
          this.$message({
            showClose: true,
            message: "该课程已下架",
            type: "warning",
          });
          return;
        }
        if (this.course.course_is_expire == 1) {
          this.$message({
            showClose: true,
            message: "该课程已过期",
            type: "warning",
          });
          return;
        }
      let islogin = await this.islogin();
      if (islogin) {
        // 是否加入学习
        if (this.course.is_join) {
          var { course_id, chapter_id, id } = item_section;
          // console.log(course_id, chapter_id, id);

          let { href } = this.$router.resolve(
            `/course_watch/${course_id}/${chapter_id}/${id}`
          );
          window.open(href, "_blank");
          // uni.navigateTo({
          // 	url: "/pages/course/watch/watch?course_id=" + course_id + '&chapter_id=' +
          // 		chapter_id + '&sec_id=' + id
          // })
        } else {
          // 点播  上线   试听
          if (
            item_section.section_is_online &&
            item_section.section_audition
          ) {
            if(this.course.course_is_free){
              this.toUrl(item_section)
            }else{
              if(this.course.is_address){
                this.toUrl(item_section)
              }else{
                this.sign_up(item_section)
              }
            }
          } else {
            if(this.course.course_is_free){
              this.$notify({
                title: "社科赛斯考研提醒您",
                message: "请先报名~",
                type: "info",
              })
            }else{
              // 判断是否需要物流
              if(this.course.is_address){
                var base64_checked_str = Base64.encode(
                  JSON.stringify([
                    {
                      good_id: this.course.good_id,
                      num: 1,
                    },
                  ])
                );
                let { href } = this.$router.resolve(
                  `/order_info/${base64_checked_str}`
                );
                window.open(href, "_blank");
              }else{
                this.sign_up(item_section)
              }
            }
          }
        }
      } else {
        this.$router.push(`/login?re_url=${this.re_url}`);
      }
    },
    async sign_up(item_section){
      const res = await api.sing_up({
        course_id: this.course.id,
      });
      if(res.code==0){
        this.toUrl(item_section)
      }else{
        this.$message('请稍后再试')
      }
    },
    toUrl(item_section){
      var { course_id, chapter_id, id } = item_section;
      let { href } = this.$router.resolve(
        `/course_watch/${course_id}/${chapter_id}/${id}`
      );
      window.open(href, "_blank");
    },

    islogin() {
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    // console.log(this.course);
  },
};
</script>

<style lang="less" scoped>
.course_timetable {
  padding: 24px 35px;
  border-top: 1px solid #e9e9e9;
  .connent_item {
    .title_ {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 22px;
        margin-right: 11px;
      }
      font-size: 18px;
      
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }
    .item_connent {
      margin-top: 23px;
      background: #fafcfe;
      margin-bottom: 30px;
      li:last-of-type {
        border: none;
      }
      li {
        padding: 20px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        cursor: pointer;
        .left {
          display: flex;
          align-items: flex-start;
          .num {
            width: 31px;
            height: 21px;
            line-height: 21px;
            background: #e9edf0;
            border-radius: 10px;
            margin-right: 13px;
            font-size: 16px;
            
            font-weight: 400;
            color: #666666;
          }
          .dec_wrap {
            .title {
              font-size: 16px;
              
              font-weight: 500;
              color: #666666;
              //   line-height: 28px;
              text-align: left;
            }
            .time {
              margin-top: 15px;
              font-size: 14px;
              
              font-weight: 400;
              line-height: 28px;
              display: flex;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
              .red {
                color: #f96158;
              }
              p {
                color: #999;
              }
              span {
                padding: 0 10px;
              }
            }
          }
        }
        .right {
          img {
            margin-right: 37px;
          }
          .btn {
            width: 100px;
            height: 32px;
            border: 1px solid #999999;
            border-radius: 16px;
            font-size: 16px;
            
            font-weight: 400;
            color: #999999;
            line-height: 32px;
            cursor: pointer;
          }
          .btn.red{
            border: 1px solid #f96158;
            color: #f96158;
          }
          .living {
            border: 1px solid #f96158;
            color: #f96158;
            img {
              width: 12px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
